<template>
  <AdminView>
    <h1 class="title-1">Programmes</h1>

    <div class="buttons-container">
      <div class="search">
        <Input title="Rechercher" v-model="search" />
      </div>

      <router-link to="programmes/messages-audio">
        <Button title="Modifier les messages audio" type="primary" />
      </router-link>

      <router-link to="programmes/new">
        <Button title="Ajouter" type="secondary">
          <SVGPlus />
        </Button>
      </router-link>
    </div>

    <table>
      <thead>
        <tr>
          <th></th>
          <th>Image</th>
          <th class="sortable" @click="sort('name')">Titre</th>
          <th>Capacités fonctionnelles</th>
          <th>Pathologie / Symptome</th>
          <th class="sortable" @click="sort('duration')">Durée</th>
          <th>Thème principal</th>
          <th class="sortable" @click="sort('exercisesCount')">Nombre d'exercises</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(program, i) in sortedPrograms" :key="i">
          <td class="edit">
            <router-link
              :to="{ name: 'Program', params: { id: program.id } }"
              title="Modifier"
            >
              <SVGEdit />
            </router-link>
          </td>
          <td class="image">
            <img
              :src="program.exercises[0].thumbnailUrl"
              v-if="program.exercises.length > 0 && program.exercises[0].thumbnailUrl"
            />
          </td>
          <td>{{ program.name }}</td>
          <td>{{ programFunctionalCapacity(program) }}</td>
          <td>{{ programPathologie(program.pathologies) }}</td>
          <td>{{ programDuration(program.exercises) }}s</td>
          <td>
            <div v-for="theme in mainTheme(program)" :key="theme">
              {{ $t(`exercise.types.${theme}`) }}
            </div>
          </td>
          <td>{{ program.exercises.length }}</td>
        </tr>
      </tbody>
    </table>
  </AdminView>
</template>

<script>
import { mapGetters } from "vuex"
import AdminView from "@/components/AdminView"
import Input from "@/components/Forms/Fields/Input"
import Button from "@/components/Button"
import SVGEdit from "@/assets/icons/edit.svg"
import SVGPlus from "@/assets/icons/plus.svg"

export default {
  name: "ProgramsList",
  components: {
    AdminView,
    Input,
    Button,
    SVGEdit,
    SVGPlus
  },
  data() {
    return {
      search: "",
      sortBy: "name"
    }
  },
  async created() {
    await this.$store.dispatch("admin/getPrograms")
  },
  methods: {
    sort(type) {
      if (this.sortBy === type) {
        this.sortBy = "-" + type
      } else {
        this.sortBy = type
      }
    },
    programDuration(exercises) {
      return exercises.reduce(
        (lastDuration, nextExercise) =>
          lastDuration +
          nextExercise.duration * nextExercise.count +
          nextExercise.restTime * (nextExercise.count - 1),
        0
      )
    },
    programPathologie(pathologies) {
      return pathologies.map((a) => a.name).join(" - ")
    },
    programFunctionalCapacity(program) {
      return program.functionalCapacity
        .map((el) => this.$t("functionalCapacities")[el.index])
        .join(" - ")
    },
    findNumberOfOccurence(arr) {
      return arr.reduce((obj, e) => {
        obj[e] = (obj[e] || 0) + 1
        return obj
      }, {})
    },
    mainTheme(program) {
      const types = program.exercises.map((exercise) => exercise.type)
      const occurences = this.findNumberOfOccurence(types)
      const max = Math.max(...Object.keys(occurences).map((k) => occurences[k]))
      return Object.keys(occurences)
        .filter((k) => occurences[k] === max)
        .map((k) => Number(k))
    }
  },
  computed: {
    ...mapGetters({
      programs: "admin/programs"
    }),
    filteredPrograms() {
      return this.programs.filter((p) =>
        p.name.toLowerCase().includes(this.search.toLowerCase())
      )
    },
    sortedPrograms() {
      const order = this.sortBy.startsWith("-") ? -1 : 1
      const sortBy = order < 0 ? this.sortBy.substring(1) : this.sortBy

      return [...this.filteredPrograms].sort((a, b) => {
        if (sortBy == "duration") {
          return this.programDuration(a.exercises) > this.programDuration(b.exercises)
            ? 1 * order
            : -1 * order
        } else if (sortBy == "exercisesCount") {
          return a.exercises.length > b.exercises.length ? 1 * order : -1 * order
        } else {
          return a[sortBy] > b[sortBy] ? 1 * order : -1 * order
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 40px 0;

  div {
    flex: 0;
  }
}

.programs-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

table {
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;

  thead {
    font-size: 1.1rem;
    font-weight: 420;
    text-align: left;
    background: $main-color;
    color: $white-color;

    .sortable {
      cursor: pointer;
      transition: all 0.25s;

      &:hover {
        opacity: 0.75;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n) {
        background: #f7f7f7;
      }
    }
  }

  th,
  td {
    padding: 8px 10px;
    vertical-align: middle;
  }

  th {
    padding: 10px;
    user-select: none;
  }

  .image {
    padding: 2px;
    width: 50px;
    height: 38px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
      border-radius: 5px;
    }
  }

  .icon {
    text-align: center;

    svg {
      width: 20px;
      color: $main-color;
    }
  }

  .edit {
    a {
      display: block;

      svg {
        width: 20px;
        transition: all 0.25s;
      }

      &:hover {
        svg {
          fill: $main-color;
        }
      }
    }
  }
}
</style>
